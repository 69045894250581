.navbar-custom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* This will space out the logo and the toggle button */
    width: 100%;
}

.navbar-brand-custom {
    z-index: 2;
    /* Ensure logo is above the toggle button when collapsed */
}

.navbar-custom .nav-link {
    display: flex;
    align-items: center;
    /* Aligns the text vertically */
    height: 50px;
    /* Match this to the height of your logo */
}

/* select all a tag under nav tag */
.navbar-custom a {
    font-size: larger;
}

/* Increased specificity by targeting 'a' tags with 'active' class inside '.navbar-custom' */
.navbar-custom .navbar-nav .nav-link.active {
    font-weight: bold;
    /* Make the active link bold */
    color: #004AAC !important;
    /* Force the text color change for active link */
}


/* Ensures toggle button is on the right for smaller screens */
@media (max-width: 600px) {
    .navbar-custom {
        flex-direction: row;
        /* Keep logo and toggle button in a row */
        justify-content: space-between;
        /* Space between logo and toggle */
    }

    .navbar-custom .navbar-collapse {
        flex-basis: 100%;
        /* Full width for collapsed content */
        order: 3;
        /* Ensures collapsed content comes after logo and toggle */
    }

    .navbar-brand-custom {
        order: 1;
        /* Logo first */
    }

    .navbar-custom .navbar-toggler {
        order: 2;
        /* Toggle button second */
        margin-right: 15px;
        /* Add margin to the right of the toggle button */
    }
}